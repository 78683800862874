import React, { useState, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import { Sidebar } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, TextInput } from "flowbite-react";
import {
  defaultTemplatesGroup,
  createTemplate,
} from "../APIservice/apiService";
import { getDefaultTemplateById, saveDesign, getPreview } from "../api";
import axios from "axios";
import Cookies from "js-cookie";

const TempModel = ({ isOpen, onClose }) => {
  const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;

  const theme = {
    "root": {
      "base": "h-full",
      "collapsed": {
        "on": "w-16",
        "off": "w-64"
      },
      "inner": "h-full overflow-y-auto overflow-x-hidden rounded bg-gray-50 px-3 py-4"
    },
    "collapse": {
      "button": "group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-white-100  dark:hover:bg-black",
      "icon": {
        "base": "h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-black",
        "open": {
          "off": "",
          "on": "text-gray-900"
        }
      },
      "label": {
        "base": "ml-3 flex-1 whitespace-nowrap text-left",
        "icon": {
          "base": "h-6 w-6 transition delay-0 ease-in-out",
          "open": {
            "on": "rotate-180",
            "off": ""
          }
        }
      },
      "list": "space-y-2 py-2"
    },
    "cta": {
      "base": "mt-6 rounded-lg bg-gray-100 p-4 ",
      "color": {
        "blue": "bg-cyan-50 dark:bg-cyan-900",
        "dark": "bg-dark-50 dark:bg-dark-900",
        "failure": "bg-red-50 dark:bg-red-900",
        "gray": "bg-alternative-50 dark:bg-alternative-900",
        "green": "bg-green-50 dark:bg-green-900",
        "light": "bg-light-50 dark:bg-light-900",
        "red": "bg-red-50 dark:bg-red-900",
        "purple": "bg-purple-50 dark:bg-purple-900",
        "success": "bg-green-50 dark:bg-green-900",
        "yellow": "bg-yellow-50 dark:bg-yellow-900",
        "warning": "bg-yellow-50 dark:bg-yellow-900"
      }
    },
    "item": {
      "base": "flex items-center justify-center rounded-lg p-2 text-base font-normal text-white-900 font-semibold dark:hover:text-white dark:hover:bg-black",
      "active": "bg-white dark:bg-white-700 dark:text-white dark:group-hover:text-white",
      "collapsed": {
        "insideCollapse": "group w-full pl-8 transition duration-75",
        "noIcon": "font-bold"
      },
      "content": {
        "base": "flex-1 whitespace-nowrap px-3"
      },
      "icon": {
        "base": "h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
        "active": "text-gray-700 dark:text-gray-100"
      },
      "label": "",
      "listItem": ""
    },
    "items": {
      "base": "bg-white"
    },
    "itemGroup": {
      "base": "mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700"
    },
    "logo": {
      "base": "mb-5 flex items-center pl-2.5",
      "collapsed": {
        "on": "hidden",
        "off": "self-center whitespace-nowrap text-xl font-semibold dark:text-white"
      },
      "img": "mr-3 h-6 sm:h-7",
      "position": {
        "none": "",
        "start": "rounded-r-none focus:ring-2 bg-black text-white",
        "middle": "rounded-none border-l-0 pl-0 focus:ring-2 ",
        "end": "rounded-l-none border-l-0 pl-0 focus:ring-2"
      }
    }
  };

  const getAuthToken = () => Cookies.get("token");
  const { projectId } = useParams();

  const [activeItem, setActiveItem] = useState("1280x720"); // initial active item set to "custom"
  const [customactive, setCustomactive] = useState("1280x720"); // initial active item set to "custom"
  const [openModal, setOpenModal] = useState(isOpen);
  const [getModelData, setGetModelData] = useState({});
  const [sizeData, setSizeData] = useState([]);
  const [templateSizes, setTemplateSizes] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [customWidth, setCustomWidth] = useState("1280");
  const [customHeight, setCustomHeight] = useState("720");

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setActiveItem(item);
    if (item === "custom") {
      setShowCustomItems(true);
    } else {
      setShowCustomItems(false);
      setSizeData(getModelData[item]);
    }
    console.log("this is custom button", item);
    setSizeData(getModelData[item]);
  };

  const handleItemClick2 = (item) => {
    setCustomactive(item);
    setCustomWidth(item.split("x")[0]);
    setCustomHeight(item.split("x")[1]);
  };

  const handlecustomwidth = (event) => {
    console.log("custom width", event.target.value);
    setCustomWidth(event.target.value);
  };
  const handlecustomHeight = (event) => {
    console.log("custom width", event.target.value);
    setCustomHeight(event.target.value);
  };

  const fetchModel = async () => {
    try {
      const response = await defaultTemplatesGroup();
      setTemplateSizes(Object.keys(response));
      setGetModelData(response);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }
  };

  const createTemplate = async () => {
    try {
      const response = await createTemplate();
      console.log(response);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }
  };

  useEffect(() => {
    setOpenModal(isOpen);
    fetchModel();
  }, [isOpen]);

  useEffect(() => {
    if (getModelData[activeItem]) {
      setSizeData(getModelData[activeItem]);
    }
  }, [getModelData, activeItem]);

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  // const handleImageClick = (id) => {
  //     localStorage.setItem('templateId', id);
  //     console.log(id)
  //     navigate(`/projects/${projectId}/template/`);
  // };
  // const handleImageClick = async (template) => {
  //     // localStorage.setItem('templateId', id);
  //     console.log(template,'click id');

  //     // createTemplate(template)

  // // Parse the JSON content
  // // const jsonData = await jsonResponse?.storeJSON.json();
  // // console.log(jsonData,"data is Here")
  // const apiRequest = async ({url, method, headers = {}, params = {}, data = null}) => {
  //     const token = getAuthToken();
  //     if (!token) {
  //         throw new Error("Authorization token is missing");
  //     }

  //     const authHeaders = { 'Authorization': `Bearer ${token}` };
  //     const request = {
  //         method,
  //         url: `${API_BASE_URL}${url}`,
  //         headers: { ...authHeaders, ...headers },
  //         params,
  //     };

  //     if (data) {
  //         request.data = data;
  //     }

  //     const response = await axios(request);
  //     return response.data;
  // };

  // try {
  //     const response = await getDefaultTemplateById({id:template?.id});
  //     const res = response.storeJSON
  //     const saveDesign =  () => {
  //         const formData = new FormData();

  //         formData.append('template_name', 'undefined');
  //         formData.append('projectId', projectId);
  //         formData.append('template_data', JSON.stringify(response.storeJSON));
  //         formData.append('thumbnail', template.preview_path);

  //         return apiRequest({
  //             url: '/templates',
  //             method: 'POST',
  //             data: formData,
  //             headers: { 'Content-Type': 'multipart/form-data' }
  //         });
  //     };
  // } catch (error) {
  //     console.error("Fetching model failed:", error);
  // }
  //     // navigate(`/projects/${projectId}/template/`);
  // };

  const apiRequest = async ({
    url,
    method,
    headers = {},
    params = {},
    data = null,
  }) => {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authorization token is missing");
    }

    const authHeaders = { Authorization: `Bearer ${token}` };
    const request = {
      method,
      url: `${API_BASE_URL}${url}`,
      headers: { ...authHeaders, ...headers },
      params,
    };

    if (data) {
      request.data = data;
    }

    const response = await axios(request);
    return response.data;
  };

  const handleImageClick = async (template) => {
    // console.log(template, "click id");

    //   const fetchThumbnailAsBinary = async (url) => {
    //     const response = await fetch(url, { mode: "cors" });
    //     const arrayBuffer = await response.arrayBuffer();
    //     const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Adjust the type based on the image format
    //     return blob;
    // };

    const fetchThumbnailAsBinary = async (url) => {
      try {
        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const urlWithProxy = `${url}`;
        const response = await fetch(urlWithProxy, { mode: "no-cors" });
        const arrayBuffer = await response.arrayBuffer();
        const contentType = response.headers.get("content-type");
        const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
        return blob;
      } catch (error) {
        console.error("Error fetching or converting image:", error);
      }
    };

    try {
      const response = await getDefaultTemplateById({ id: template?.id });
      // console.log(response,"HERE IT WAS")
      const res = response.storeJSON;

      const saveDesign = async () => {
        const formData = new FormData();

        formData.append("template_name", "undefined");
        formData.append("projectId", projectId);
        formData.append("template_data", JSON.stringify(res));
        try {
          const thumbnailBlob = await fetchThumbnailAsBinary(
            template.preview_path
          );
          console.log(thumbnailBlob, "BLOB IS HERE");
          formData.append("thumbnail", thumbnailBlob); // Assuming the image is a PNG
        } catch (error) {
          console.error("Failed to fetch thumbnail:", error);
          return;
        }

        try {
          const response = await apiRequest({
            url: "/templates",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          // console.log('Design saved successfully');
          return response; // Return the response from apiRequest
        } catch (error) {
          // console.error('Saving design failed:', error);
          return null; // Return null or an appropriate value in case of an error
        }
      };

      // const callAnotherApi = async (savedTemplateId) => {
      //   const formData = new FormData();

      //   // Assuming the same data needs to be sent, modify as needed
      //   formData.append("template_name", "undefined");
      //   formData.append("projectId", projectId);
      //   formData.append("template_data", JSON.stringify(res));
      //   formData.append("template_id", savedTemplateId);
      //   try {
      //     const thumbnailBlob = await fetchThumbnailAsBinary(
      //       template.preview_path
      //     );
      //     formData.append("thumbnail", thumbnailBlob); // Assuming the image is a PNG
      //   } catch (error) {
      //     console.error("Failed to fetch thumbnail:", error);
      //     return;
      //   }
      //   // You can also pass any additional data in this API call as needed

      //   try {
      //     const anotherApiResponse = await apiRequest({
      //       url: `/templates`, // Adjust the endpoint as needed
      //       method: "POST",
      //       data: formData,
      //       headers: { "Content-Type": "multipart/form-data" },
      //     });

      //     // console.log("Another API Response:", anotherApiResponse);
      //     localStorage.setItem('templateId', saveResponse.id);
      //   navigate(`/projects/${projectId}/template/${saveResponse.id}`);

      //   } catch (error) {
      //     console.error("Another API failed:", error);
      //   }
      // };

      const saveResponse = await saveDesign();

      // if (saveResponse && saveResponse.id) {
      //   console.log("Save Design Response:", saveResponse);
      //   // Navigate to the saved design's page
      //   // Call the second API with the saved template ID
      //   await callAnotherApi(saveResponse.id);
      // } else {
      //   console.error("Save Design failed, response is null.");
      // }

      console.log("Save Design Response:", saveResponse);
      navigate(`/projects/${projectId}/template/${saveResponse.id}`);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }

    // Navigate to a different route
    // navigate(`/projects/${projectId}/template/`);
  };

  const handleCustomClick = (width, height) => {
    const jsonStore = {
      width: parseInt(width),
      height: parseInt(height),
      fonts: [],
      pages: [
        {
          id: "S5YfeK-vMM",
          children: [],
          width: "auto",
          height: "auto",
          background: "white",
          bleed: 0,
          duration: 5000,
        },
      ],
      unit: "px",
      dpi: 72,
    };

    const jsonString = JSON.stringify(jsonStore);
    const blob = new Blob([jsonString], { type: "application/json" });
    console.log(blob, "here is blob");

    const saveDesign = async () => {
      const formData = new FormData();

      formData.append("template_name", "undefined");
      formData.append("projectId", projectId);
      formData.append("template_data", JSON.stringify(jsonStore));
      formData.append("thumbnail", blob);

      try {
        const response = await apiRequest({
          url: "/templates",
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        // console.log(response); // Log the response from apiRequest
        navigate(`/projects/${projectId}/template/${response.id}`);
      } catch (error) {
        console.error("Saving design failed:", error);
        return null; // Return null or an appropriate value in case of an error
      }
    };

    // Call the saveDesign function
    saveDesign();

    // Navigate to a different route if needed
    // navigate(`/projects/${projectId}/template/`);
  };

  function generateViewBox(customWidth, customHeight) {
    return `0 0 ${customWidth} ${customHeight}`;
    return;
  }

  console.log(templateSizes, "templateSizes");
  return (
    <>
      

      <Modal
        show={openModal}
        size={"6xl"}
        onClose={() => handleClose()}
        className="z-50 bg-black dark:bg-black "
      >
        <Modal.Header className="bg-black dark:bg-black dark:border-2 dark:border-white ">
          {/* <ButtonGroup outline className="flex justify-center space-x-0 border-gray-700">
            <Button
              color={activeItem !== "custom" ? "blue" : "white"}
              onClick={() => handleItemClick("1280x720")}
              className={`w-[60px] md:w-20 ${
                activeItem === "1280x720" ? "active " : ""
              }`}
            >
              Presets
            </Button>
            <Button
              color={activeItem === "custom" ? "blue" : "white"}
              onClick={() => handleItemClick("custom")}
              className={`w-[70px] md:w-20 ${
                activeItem === "custom" ? "active" : ""
              }`}
            >
              Customs
            </Button>
          
          </ButtonGroup> */}

          <Button.Group outline >
            <Button
              onClick={() => handleItemClick("1280x720")}
              className={`w-[60px] md:w-20 ${
                ["1280x720", "1080x1920", "1080x1080", "940x788", "851x315", "1200x628", "1920x1080", "2560x1440", "1500x500", "1200x1200", "1584x396", "816x1056", "1000x1500", "1600x900", "510x660"].includes(activeItem) ? " dark:text-black dark:bg-white hover:bg-white focus:text-white hover:text-white bg-black text-white" : "dark:text-white  text-black"
              }`}
              color="gray"
            >
              Presets 
            </Button>
            <Button
              color="gray"
              onClick={() => handleItemClick("custom")}
              className={`w-[70px] md:w-20 ${
                activeItem === "custom" ? " dark:text-black dark:bg-white hover:bg-white  focus:text-white  hover:text-white bg-black text-white" : "dark:text-white text-black"
              }`}
            >
              Customs
            </Button>
          </Button.Group>
        </Modal.Header>

        <Modal.Body className=" overflow-y-auto bg-white dark:border-2 dark:border-white">
          {/* Flex Container for Responsive Layout */}
          <div className="flex flex-row md:flex-nowrap p-0 h-auto md:h-[600px] bg-white dark:bg-white">
            {/* Left Sidebar Content for Mobile and Desktop */}
            <div className="w-full md:w-[28%] h-auto md:h-full dark:bg-white">
              <Sidebar
                theme={theme}
                aria-label="Sidebar with multi-level dropdown example"
                className="overflow-y-auto h-[300px] md:h-full w-full dark:bg-black"
              >
                <Sidebar.Items>
                  <Sidebar.ItemGroup>
                    {!showCustomItems &&
                      templateSizes.map((size) => (
                        <Sidebar.Item
                          key={size}
                          href="#"
                          onClick={() => handleItemClick(size)}
                          className={
                            activeItem === size
                              ? " text-white bg-black hover:bg-black hover:text-white"
                              : "text-black hover:text-black focus:text-black"
                          }
                        >
                          {size}
                        </Sidebar.Item>
                      ))}
                    {showCustomItems &&
                      templateSizes.map((size) => (
                        <Sidebar.Item
                          key={size}
                          href="#"
                          onClick={() => handleItemClick2(size)}
                          className={
                            customactive === size
                              ? "text-white bg-black hover:bg-black hover:text-white"
                              : "text-black hover:text-black focus:text-black"
                          }
                        >
                          {size}
                        </Sidebar.Item>
                      ))}
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </Sidebar>
            </div>

            {/* Right Side Content */}
            <div className="w-full md:w-[72%] h-auto md:h-full">
              {activeItem !== "custom" && (
                <div className="w-full p-4  border-black rounded-[0.25rem] dark:border-black h-[300px] md:h-full bg-white">
                  <div className="w-full h-full overflow-y-auto bg-white">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
                      {sizeData &&
                        sizeData.map((template, index) => (
                          <div
                            key={template.id}
                            onClick={() => handleImageClick(template)}
                            className="template rounded cursor-pointer hover:scale-[1.05] h-auto"
                          >
                            <img
                              src={template.preview_path}
                              alt={`Template ${index + 1}`}
                              width={template.width}
                              height={template.height}
                              className="rounded w-full h-full object-cover"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {activeItem === "custom" && (
                <div className="w-full h-[300px] md:h-full bg-[#1F2937] rounded-[0.25rem] flex justify-center items-center overflow-y-auto flex-col">
                  <div className="w-full p-4 h-full flex justify-center items-center bg-black">
                    <svg
                      className="max-w-[95%] max-h-[95%] bg-white"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      viewBox={generateViewBox(customWidth, customHeight)}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        fill="currentColor"
                        className="text-white dark:text-white bg-white"
                        strokeWidth="1"
                        width={customWidth}
                        height={customHeight}
                        rx=""
                        ry=""
                      ></rect>
                    </svg>
                  </div>
                  {/* <div className="flex justify-center sm:justify-end w-full py-3 px-5 bg-[#1F2937]">
              <button
                onClick={() => handleCustomClick(customWidth, customHeight)}
                className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-1.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              >
                Create
              </button>
            </div> */}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        {activeItem === "custom" && (
          <Modal.Footer className="flex justify-end p-[0.5rem] mb-2 md:mb-0 bg-black dark:bg-black px-5 dark:border-2 dark:border-white">
            {/* <Button
           className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-1.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" >
          Create
          </Button> */}
            <span className="hidden md:block text-white text-base ">
              Custom Width and Height
            </span>
            <input
              type="text"
              className="p-2 border w-20 h-[37px] md:w-20  border-black-300 rounded-lg "
              value={customWidth}
              onChange={handlecustomwidth}
            />
            <span className="text-white text-base">x</span>
            <input
              type="text"
              className="p-2 border w-20 h-[37px] md:w-20  border-black-300 rounded-lg "
              value={customHeight}
              onChange={handlecustomHeight}
            />

            <button
              onClick={() => handleCustomClick(customWidth, customHeight)}
              className="p-[25px] py-2.5 text-xs md:text-sm font-medium rounded-full inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              Create
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default TempModel;
