import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Spinner, InputGroup, Switch } from '@blueprintjs/core';
import * as api from '../api';
import { SectionTab } from 'raeditor/side-panel';
import { useProject } from '../project';
import { BsFillGrid1X2Fill } from "react-icons/bs";

const TemplateCard = observer(({ template, onClick }) => {
    const [loading, setLoading] = useState(false);

    const handleSelect = async () => {
        setLoading(true);
        await onClick(template.id);
        setLoading(false);
    };

    return (
        <Card
            style={{ margin: '10px 5px', padding: '0px', position: 'relative', borderRadius: '5px' }}
            interactive
            onClick={handleSelect}
        >
            <img src={template.preview_path} style={{ width: '100%', borderRadius: '5px' }} alt="Template preview" />
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Spinner />
                </div>
            )}
        </Card>
    );
});

export const DefaultTemplatesPanel = observer((store) => {
    const project = useProject();
    const [templatesLoading, setTemplatesLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [sameSize, setSameSize] = useState(false);
    const scrollableRef = useRef(null);

    const loadTemplates = async (page = 1, search = '', width, height) => {
        setTemplatesLoading(true);
        try {
            const list = await api.listDefaultTemplates(page, search, width, height);
            if (page === 1) {
                setTemplates(list.data);
            } else {
                setTemplates(prevTemplates => [...prevTemplates, ...list.data]);
            }
        } catch (error) {
            console.error('Failed to load templates:', error);
        } finally {
            setTemplatesLoading(false);
        }
    };

    const handleTemplateClick = async (id) => {
        // const activePage = store.activePage;
        const template = await window.project.defaultLoadById(id);
        console.log('Selected Template:', id);
        // console.log(activePage,"template data is here")
    };

    useEffect(() => {
        const width = sameSize ? window.project.store.activePage?.computedWidth : null;
        const height = sameSize ? window.project.store.activePage?.computedHeight : null;
        loadTemplates(1, search, width, height);
    }, [project.cloudEnabled, project.designsLength, search, sameSize]);

    const handleScroll = () => {
        if (scrollableRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && !templatesLoading) {
                setPage(prevPage => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        const width = sameSize ? window.project.store.activePage?.computedWidth : null;
        const height = sameSize ? window.project.store.activePage?.computedHeight : null;
        loadTemplates(page, search, width, height);
    }, [page]);

    const handleSearchChange = (event) => {
        
        // setSearch(event.target.value);
        // setPage(1);
        const newValue = event.target.value;
        if (newValue.trim() !== '') { // Check if the input is not just whitespace
            setSearch(newValue);
            setPage(1);
        } else {
            setSearch(''); // Reset search state
        }
    };

    const handleToggleChange = () => {
        setSameSize(!sameSize);
    };

    const half1 = [];
    const half2 = [];

    templates.forEach((template, index) => {
        if (index % 2 === 0) {
            half1.push(template);
        } else {
            half2.push(template);
        }
    });

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '10px' }}>
            <InputGroup
                leftIcon="search"
                onChange={handleSearchChange}
                placeholder="Search templates"
                value={search}
                style={{ marginBottom: '10px' }}
            />
            <Switch
                label="Show templates with the same size"
                checked={sameSize}
                onChange={handleToggleChange}
                style={{ marginBottom: '10px' }}
            />
            {templatesLoading && (
                <div style={{ padding: '30px', textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            <div
                ref={scrollableRef}
                onScroll={handleScroll}
                style={{
                    display: 'flex',
                    paddingTop: '5px',
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                <div style={{ width: '50%' }}>
                    {half1.map((template) => (
                        <TemplateCard
                            template={template}
                            key={template.id}
                            onClick={handleTemplateClick}
                        />
                    ))}
                </div>
                <div style={{ width: '50%' }}>
                    {half2.map((template) => (
                        <TemplateCard
                            template={template}
                            key={template.id}
                            onClick={handleTemplateClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

// Define the new custom section
export const DefaultTemplatesSection = {
    name: 'templates',
    Tab: (props) => (
        <SectionTab name="Templates" {...props}>
            <BsFillGrid1X2Fill className='w-5 h-5' />
        </SectionTab>
    ),
    Panel: DefaultTemplatesPanel,
};
