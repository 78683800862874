import React,{useEffect,useContext, useState} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { createStore } from "raeditor/model/store";
import { useNavigate } from "react-router-dom";
import {
    unstable_setAnimationsEnabled,
    unstable_setRemoveBackgroundEnabled,
    unstable_setTextVerticalResizeEnabled
} from "raeditor/config";
import { createProject, ProjectContext } from "./project";
import "./index.css";
import App from "./App";
import "./logger";
import { ErrorBoundary } from "react-error-boundary";
import Login from "./auth/login";
import Login1 from "./auth/login1";
import Signup from "./auth/signUp";
import Templates from "./pages/templates";
import NotFoundPage from "./pages/404";
import Header from "./header/header";
import ProjectTab from "./project/projectTab";
import DashboardTab from "./dashboard/dashboardTab";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/profile";
import PrivateRoute from "./PrivateRoute";
import Integration from "./pages/integration";
import Forgot from "./auth/forgotPassword";
import Reset from "./auth/reset";
import Cookies from "js-cookie";
import { UserProvider } from './context/UserContext';
import Editor from "./editor"
import { getProfile } from "./APIservice/apiService";
import { UserContext } from "./context/UserContext";
import { toast } from "react-toastify";
import ThemeToggle from "./components/ThemeToggle";
unstable_setAnimationsEnabled(false);
unstable_setRemoveBackgroundEnabled(false);
unstable_setTextVerticalResizeEnabled(true);

// Initialize theme
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}

const store = createStore();
window.store = store;
store.addPage();

const project = createProject({ store });
window.project = project;
window.store = store;
store.addPage();

const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log = () => {};



function Fallback({ error, resetErrorBoundary }) {
    
    return (
        <div style={{ width: "100vw", height: "100vh" }}>
            <div style={{ textAlign: "center", paddingTop: "40px" }}>
                <p>Something went wrong in the app.</p>
                <p>Try to reload the page.</p>
                <p>If it does not work, clear cache and reload.</p>
                <button
                    onClick={async () => {
                        await project.clear();
                        window.location.reload();
                    }}
                >
                    Clear cache and reload
                </button>
            </div>
        </div>
    );
}

function AppWithConditionalHeader() {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const hideHeaderRoutes = ["/signup", "/login", "/forgot", `/reset-password/:id`];
    const showHeader = !hideHeaderRoutes.includes(location.pathname) && !location.pathname.startsWith("/reset-password/");
    const token = Cookies.get('token');
    // const token = sessionStorage.getItem('token');

    if (token) {
        hideHeaderRoutes.push("/signup", "/login", "/hello", "/forgot", "/login1");
    } else {
        <Navigate to="/login" />
    }

    const { UserName, updateUser } = useContext(UserContext);
    const [currentPath, setCurrentPath] = React.useState(window.location.pathname);
   

    const fetchData = async () => {
        // Define routes where the API call should be skipped
        const hideHeaderRoutes = ["/signup", "/login", "/forgot", `/reset-password/:id`];

        // Check if the current route is in hideHeaderRoutes
        if (hideHeaderRoutes.includes(location.pathname) || location.pathname.startsWith("/reset-password/")) {
            return; // Skip API call if on a specified route
        }

        try {
            const response = await getProfile();
            if (response) {
                updateUser({ name: response.data.data.name,plan:response.data.data.plan });
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [location.pathname]);
    
    const [isOnline, setIsOnline] = React.useState(navigator.onLine); // Track online status

    useEffect(() => {
        const updateOnlineStatus = () => setIsOnline(navigator.onLine);
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    // THis code added for not allow inspect element in website 
    const logout = () => {
        // sessionStorage.removeItem('token');
        // sessionStorage.removeItem('user');
        Cookies.remove("token"); // Removes the 'token' cookie
        Cookies.remove("user");
        sessionStorage.removeItem("savedImages");
        sessionStorage.clear();
        navigate("/login");
      };

      let devtoolsCalled = false;
     const devtoolsOpen = () => {
        const threshold = 160; // Threshold for width/height of dev tools
        return window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold;
    };

    // Check for dev tools and redirect if open
    // setInterval(() => {
    //     if (devtoolsOpen() && !devtoolsCalled ) {
    //         devtoolsCalled = true; 
    //         logout() // Change to your desired URL
    //     }
    // }, 1000); // Check every second

    // document.addEventListener('contextmenu', (event) => {
    //     event.preventDefault();
    // });


    // document.addEventListener('contextmenu', (event) => {
    //     event.preventDefault();
    // });


    // document.onkeydown = (e) => {
    //     if (e.ctrlKey && e.shiftKey && e.key == 'I') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.key == 'C') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.key == 'J') {
    //         e.preventDefault();
    //     }
    //     if (e.ctrlKey && e.key == 'U') {
    //         e.preventDefault();
    //     }
    // };
    
    return (
        <>
            {!isOnline && (
                <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center' }}>
                    No internet connection. Please check your network settings.
                </div>
            )}
           
            {/* <ThemeToggle /> */}
            {showHeader && <Header />}
            {/* <div className="fixed top-20 left-4 p-4 rounded-lg bg-white dark:bg-gray-800 text-black dark:text-white shadow-lg transition-colors">
              <h2 className="text-xl font-bold mb-2">Theme Test</h2>
              <p>This content will switch between light and dark themes</p>
            </div> */}
            <ProjectContext.Provider value={project}>
                
                <Routes>
                    {token ? (
                        <>
                            <Route path="/login" element={<Navigate to="/dashboard" />} />
                            <Route path="/signup" element={<Navigate to="/dashboard" />} />
                            <Route path="/" element={<Navigate to="/dashboard" />} />
                            <Route path="/forgot" element={<Navigate to="/dashboard" />} />
                            <Route path="/reset-password/:id" element={<Reset />} />
                        </>
                    ) : (
                        <>
                            <Route path="/login" element={<Login />} />
                            {/* <Route path="/login1" element={<Login1 />} /> */}
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/" element={<Navigate to="/login" />} />
                            <Route path="/forgot" element={<Forgot />} />
                            <Route path="/reset-password/:id" element={<Reset />} />
                        </>
                    )}
                    <Route element={<PrivateRoute />}>
                        {/* {/ <Route path="/projects/:projectId/template/:templateId?" element={<Editor store={store} />} /> /} */}
                        <Route path="/projects/:projectId/template/:templateId?" element={<App store={store} />} />
                        {/* <Route path="/projects/:projectId1/template/wp/:imageurl?" element={<App store={store} />} /> */}
                        <Route path="/projects/:projectId1/template/wp-template-i" element={<App store={store} />} />
                        <Route path="/projects/:projectId" element={<Templates />} />
                        <Route path="/dashboard" element={<DashboardTab />} />
                        <Route path="/projects" element={<ProjectTab />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/templates-embed/:id" element={<Integration />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </ProjectContext.Provider>
        </>
    );
}



root.render(
    <BrowserRouter>
        <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={(details) => {
            }}
            onError={(e) => {
                if (window.Sentry) {
                    window.Sentry.captureException(e);
                }
            }}
        >
            <UserProvider>
                <AppWithConditionalHeader />
            </UserProvider>
            <ToastContainer />
        </ErrorBoundary>
    </BrowserRouter>
);
