import React, { useState } from "react";
import { requestResetPassword } from '../APIservice/apiService';
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";

const Forgot = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
 
  const [formData, setFormData] = useState({
    email: "",
    host: "https://skinlay.in"
  });

  const [errors, setErrors] = useState({});

  const sendEmail = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if(Object.keys(errors).length > 0){
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const response = await requestResetPassword(formData.email, formData.host);
      setIsLoading(false);
      if (response.name) {
        navigate('/dashboard');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Password reset request failed:', error);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      {/* Container */}
      <div className="flex flex-row w-full">
        {/* Sidebar */}
        <div className="hidden lg:flex flex-col justify-between bg-black text-white lg:p-8 xl:p-12 lg:max-w-sm xl:max-w-lg">
          <div className="flex items-center justify-start space-x-3">
            <img
              src={require("../assets/3.png")}
              alt="logo"
              className="w-75 h-10"
            />
          </div>
          <div className="space-y-5">
            <h1 className="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold">
              Recover access to your ImageEazy account
            </h1>
            <p className="text-lg">Remember your password?</p>
            <button
              onClick={() => navigate("/login")}
              className="inline-block flex-none px-4 py-3 border-2 rounded-lg font-medium border-black bg-white text-black hover:bg-[#4c6ee2] hover:text-white duration-700"
            >
              Back to Login
            </button>
          </div>
          <p className="font-medium">@2024 ImageEazy</p>
        </div>

        {/* Forgot Password Form */}
        <div className="flex flex-1 flex-col items-center justify-center relative">
          {/* Mobile Logo Header */}
          <div className="flex lg:hidden justify-between items-center w-full py-4 px-10">
            <div className="flex items-center justify-start space-x-3">
              {/* <img
                src={require("../assets/f3.png")}
                alt="logo"
                className="w-20 "
              /> */}
            </div>
            {/* <div className="flex items-center space-x-2">
              <span>Remember password? </span>
              <button
                onClick={() => navigate("/login")}
                className="font-medium text-[#4c6ee2] hover:text-black duration-200"
              >
                Sign in
              </button>
            </div> */}
          </div>

          <div className="flex flex-1 items-center justify-center px-10 w-full">
            <div className="w-full max-w-md space-y-5">
            <div className="flex items-center justify-center">
            <img
                src={require("../assets/1.png")}
                alt="logo"
                className="w-30 lg:hidden max-w-[75%]"
              />
            </div>
              <div className="">
                <div className="flex flex-col space-y-2 text-center">
                  <h2 className="text-3xl md:text-4xl font-bold">Forgot Password</h2>
                  <p className="text-md md:text-xl">
                    Enter your email to reset your password
                  </p>
                </div>
              </div>

              <form onSubmit={sendEmail} className="flex flex-col max-w-md space-y-5">
                <div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({...formData, email: e.target.value});
                      setErrors({...errors, email: ""});
                    }}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-1 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] duration-700"
                >
                  {isLoading ? (
                    <><Spinner aria-label="Loading..." /> <span className="pl-3">Loading...</span></>
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>
              <div className="flex items-center space-x-2 justify-center lg:hidden">
              <span>Remember password? </span>
              <button
                onClick={() => navigate("/login")}
                className= " underline font-medium text-[#4c6ee2] hover:text-black duration-200 cursor-pointer"
              >
                Sign in
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
