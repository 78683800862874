import * as mobx from 'mobx';
import { createContext, useContext } from 'react';
import * as api from './api';
import {encryptId} from "./Encrypt/encode";
import { isAlive } from 'mobx-state-tree';


export const ProjectContext = createContext({});

export const useProject = () => useContext(ProjectContext);

const getFromStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const setToStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

class Project {
  id = '';
  projectId = '';
  template_uuid = '';
  name = '';
  user = {};
  skipSaving = false;
  status = 'saved'; // or 'has-changes' or 'saving'
  language = getFromStorage('raeditor-language') || navigator.language || 'en';
  cloudEnabled= true;

  constructor({ store }) {
    mobx.makeAutoObservable(this);
    this.store = store;

    store.on('change', () => {
      this.requestSave();
    });

  }

  setLanguage(lang) {
    this.language = lang;
    setToStorage('raeditor-language', lang);
  }

  requestSave() {
    this.status = 'has-changes';
    if (this.saveTimeout) {
      return;
    }
    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null;
      this.save();
    }, 5000);
  }

  async firstLoad(templateId, projectId) {
    if (templateId) {
      await this.loadById(templateId, projectId);
    }
  }

  async defaultLoadById(id, path) {
    try {
      let { storeJSON, name } = await api.getDefaultTemplateById({ id });
      if (storeJSON) {
        if (typeof storeJSON === 'string') {
          storeJSON = JSON.parse(storeJSON);
        }
        this.store.loadJSON(storeJSON);


      //   const x = 50;
      //   const y = 50;
      //   this.store.history.transaction(async () => {
      //     // const img = this.store.activePage?.addElement({ type: 'image', width, height, x, y });
      //     const img = this.store.activePage?.loadJSON(storeJSON);
      //     if (isAlive(img)) {
      //         await img.set("https://images.unsplash.com/photo-1730305948991-326bb54514c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w2MjQ0MDd8MHwxfGFsbHwzfHx8fHx8fHwxNzMyMDEyMzUzfA&ixlib=rb-4.0.3&q=80&w=200");
      //     }
      // });
      
      }
      this.name = name;
    } catch (e) {
      console.error(e);
      this.id = '';
      this.name = 'Untitled Design';
      localStorage.removeItem('raeditor-last-design-id');
    }
  }

  async loadById(id, projectId, template_uuid) {
    this.id = id;
    try {
      let { storeJSON, name } = await api.loadById({ id });
      if (storeJSON) {
        if (typeof storeJSON === 'string') {
          storeJSON = JSON.parse(storeJSON);
        }
        console.log(storeJSON,"storeJSON")
        this.store.loadJSON(storeJSON);
      }
      this.name = name;
      this.projectId = projectId;
      // let loadid = encryptId(id);
      let path = `/projects/${projectId}/template/${id}`;
      // window.history.pushState(null, '', path);
      // window.history.pushState({ id: id, projectId: projectId }, '/login', path);
    } catch (e) {
      // this.id = '';
      // this.name = 'Untitled Design';
      // localStorage.removeItem('raeditor-last-design-id');
      console.error(e,"error is here");
      if (e.response && e.response.status === 404) {
        window.location.href = `/projects/${projectId}`;
      }else{
        this.id = '';
        this.name = 'Untitled Design';
        localStorage.removeItem('raeditor-last-design-id');
      }
    }
  }

  async deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  };

  async save() {
    this.status = 'saving';
    const storeJSON = this.store.toJSON();
    this.store.pages.forEach(page => {
      page.children.forEach(child => {
        if (!child.name) {
          child.set({ name: child.id });
        }
      });
    });
    // if(isUpdated){
    //   this.store.loadJSON(storeJSON);
    // }
    const maxWidth = 200;
    const canvas = await this.store._toCanvas({
      pixelRatio: maxWidth / this.store.activePage?.computedWidth,
      pageId: this.store.activePage?.id,
    });
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/jpeg', 0.9);
    });
    // console.log(canvas,"canvas is here")
    // console.log(blob)
    try {
      console.log(storeJSON,"Store")
      const res = await api.saveDesign({
        storeJSON,
        preview: blob,
        id: this.id,
        projectId: this.projectId,
        name: this.name,
      });

      if (res.id) {
        if(!this.id){
          console.log("No ID")
          let path = `/projects/${this.projectId}/template/${res.id}`;
          // window.history.pushState(null, '', path);
        }
        this.id = res.id;
        this.template_uuid = res.template_uuid;
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 404) {
        window.location.href = `/projects`;
      }
    }
    this.status = 'saved';
  }

  

  async duplicate() {
    this.id = '';
    this.save();
  }

  async clear() {
    this.id = '';
    this.store.clear();
    this.store.addPage();
    localStorage.removeItem('raeditor-last-design-id');
  }

  async createNewDesign() {
    await this.clear();
    this.name = 'Untitled Design';
    this.id = '';
    this.store.openSidePanel('photos');
    await this.save();
  }
}





// Here is my custom function



export const createProject = (...args) => new Project(...args);