import React from 'react';
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import {
  Button,
  Position,
  Menu,
  HTMLSelect,
  Slider,
  Popover,
} from '@blueprintjs/core';
import JSZip from 'jszip';
import { downloadFile } from 'raeditor/utils/download';
import * as unit from 'raeditor/utils/unit';
import { t } from 'raeditor/utils/l10n';
import { getUserCreditsAndTemplates } from "../APIservice/apiService";
import { UserContext } from "../context/UserContext";


const saveAsVideo = async ({ store, pixelRatio, fps, onProgress }) => {
  const json = store.toJSON();
  const req = await fetch(
    'https://api.polotno.dev/api/renders?KEY=nFA5H9elEytDyPyvKL7T',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        design: json,
        pixelRatio,
        format: 'mp4',
      }),
    }
  );
  const job = await req.json();
  while (true) {
    const jobReq = await fetch(
      `https://api.polotno.dev/api/renders/${job.id}?KEY=nFA5H9elEytDyPyvKL7T`
    );
    const jobData = await jobReq.json();
    if (jobData.status === 'done') {
      downloadFile(jobData.output, 'raeditor.mp4');
      break;
    } else if (jobData.status === 'error') {
      throw new Error('Failed to render video');
    } else {
      onProgress(jobData.progress, jobData.status);
    }
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
};

export const DownloadButton = observer(({ store }) => {
  const [saving, setSaving] = React.useState(false);
  const { UserName } = useContext(UserContext);
  // console.log(UserName,"Name is here")
  const [quality, setQuality] = React.useState(1);
  const [pageSizeModifier, setPageSizeModifier] = React.useState(1);
  const [fps, setFPS] = React.useState(10);
  const [type, setType] = React.useState('png');
  const [progress, setProgress] = React.useState(0);
  const [progressStatus, setProgressStatus] = React.useState('scheduled');
 


  const getName = () => {
    const texts = [];
    store.pages.forEach((p) => {
      p.children.forEach((c) => {
        if (c.type === 'text') {
          texts.push(c.text);
        }
      });
    });
    const allWords = texts.join(' ').split(' ');
    const words = allWords.slice(0, 6);
    return words.join(' ').replace(/\s/g, '-').toLowerCase() || 'raeditor';
  };
  return (
    <Popover
      content={
        <Menu>
          <li className="bp5-menu-header">
            <h6 className="bp5-heading">File type</h6>
          </li>
          <HTMLSelect
            fill
            onChange={(e) => {
              setType(e.target.value);
              setQuality(1);
            }}
            value={type}
            style={{ 
              // maxWidth: "90px",
              border: "1px solid #ccc",
              borderRadius: "3px",
              cursor: "pointer",
              appearance: "none",
              WebkitAppearance: "none",
              MozAppearance: "none",
              backgroundImage: "url('data:image/svg+xml;utf8,<svg fill=\"gray\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7 10l5 5 5-5z\"/></svg>')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 4px center",
              paddingRight: "24px"
          }}
          >
            <option value="jpeg">JPEG</option>
            <option value="png">PNG</option>
            <option value="pdf">PDF</option>
            {/*<option value="html">HTML</option>*/}
            {/*<option value="json">JSON</option>*/}
            {/*<option value="gif">GIF</option>*/}
            {/*<option value="mp4">mp4 Video</option>*/}
          </HTMLSelect>

          {type !== 'json' && (
            <>
              <li className="bp5-menu-header">
                <h6 className="bp5-heading">Quality</h6>
              </li>
              <div style={{ padding: '10px' }}>
                <Slider
                  value={quality}
                  labelRenderer={false}
                  onChange={(quality) => {
                    setQuality(quality);
                  }}
                  stepSize={0.2}
                  min={0.2}
                  max={300 / 72}
                  showTrackFill={false}
                />
                {type === 'pdf' && (
                  <div>DPI: {Math.round(store.dpi * quality)}</div>
                )}
                {type !== 'pdf' && (
                  <div>
                    {Math.round(store.width * quality)} x{' '}
                    {Math.round(store.height * quality)} px
                  </div>
                )}
                {type === 'gif' && (
                  <>
                    <li className="bp5-menu-header">
                      <h6 className="bp5-heading">FPS</h6>
                    </li>
                    <div style={{ padding: '10px' }}>
                      <Slider
                        value={fps}
                        // labelRenderer={false}
                        labelStepSize={5}
                        onChange={(fps) => {
                          setFPS(fps);
                        }}
                        stepSize={1}
                        min={5}
                        max={30}
                        showTrackFill={false}
                      />
                    </div>
                  </>
                )}
              </div>
              {type === 'pdf' && (
                <>
                  <li className="bp5-menu-header">
                    <h6 className="bp5-heading">Page Size</h6>
                  </li>
                  <div style={{ padding: '10px' }}>
                    <Slider
                      value={pageSizeModifier}
                      labelRenderer={false}
                      onChange={(pageSizeModifier) => {
                        setPageSizeModifier(pageSizeModifier);
                      }}
                      stepSize={0.2}
                      min={0.2}
                      max={3}
                      showTrackFill={false}
                    />

                    <div>
                      {unit.pxToUnitRounded({
                        px: store.width * pageSizeModifier,
                        dpi: store.dpi,
                        precious: 0,
                        unit: 'mm',
                      })}{' '}
                      x{' '}
                      {unit.pxToUnitRounded({
                        px: store.height * pageSizeModifier,
                        dpi: store.dpi,
                        precious: 0,
                        unit: 'mm',
                      })}{' '}
                      mm
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {type === 'json' && (
            <>
              <div style={{ padding: '10px', maxWidth: '180px', opacity: 0.8 }}>
                JSON format is used for saving and loading projects. You can
                save your project to a file and load it later via "File" {'->'}{' '}
                "Open" menu.
              </div>
            </>
          )}
          {type === 'mp4' && (
            <>
              <div style={{ padding: '10px', maxWidth: '180px', opacity: 0.8 }}>
                Download your design as a video.{' '}
                <strong>This feature is VERY experimental.</strong>
                Please write to{' '}
                <a href="mailto:anton@polotno.com">anton@polotno.com</a> for any
                feedback!
              </div>
              {saving && (
                <div
                  style={{ padding: '10px', maxWidth: '180px', opacity: 0.8 }}
                >
                  {progressStatus}: {progress} %
                </div>
              )}
            </>
          )}
          <Button
            fill
            intent="primary"
            loading={saving}
            onClick={async () => {
              setSaving(true);
              try {
                if (type === 'pdf') {
                  await store.saveAsPDF({
                    fileName: getName() + '.pdf',
                    dpi: store.dpi / pageSizeModifier,
                    pixelRatio: 2 * quality,
                  });
                } else if (type === 'html') {
                  await store.saveAsHTML({
                    fileName: getName() + '.html',
                  });
                } else if (type === 'json') {
                  const json = store.toJSON();

                  const url =
                    'data:text/json;base64,' +
                    window.btoa(
                      unescape(encodeURIComponent(JSON.stringify(json)))
                    );

                  downloadFile(url, 'raeditor.json');
                } else if (type === 'gif') {
                  await store.saveAsGIF({
                    fileName: getName() + '.gif',
                    pixelRatio: quality,
                    fps,
                  });
                } else if (type === 'mp4') {
                  setProgressStatus('scheduled');
                  await saveAsVideo({
                    store,
                    pixelRatio: quality,
                    onProgress: (progress, status) => {
                      setProgress(progress);
                      setProgressStatus(status);
                    },
                  });
                  setProgressStatus('done');
                  setProgress(0);
                } else {
                  // if(UserName.plan !==)
                  // if (store.pages.length < 3) {
                  //   store.pages.forEach(async(page, index) => {
                  //     store.activePage.addElement({
                  //       type: 'image',
                  //       selectable: false, 
                  //       alwaysOnTop: true, 
                  //       showInExport: false,
                  //     });
                  //     const indexString = store.pages.length > 1 ? '-' + (index + 1) : '';
                  //     store.saveAsImage({
                  //       pageId: page.id,
                  //       pixelRatio: quality,
                  //       mimeType: 'image/' + type,
                  //       fileName: getName() + indexString + '.' + type,
                  //     });
                  //   });
                  // } 
                  // Condition with the when it was free than only it will work otherwise it will not add watermark 
                  // console.log(UserName,"Plan is here ")
                  if (store.pages.length < 3) {
                  if(UserName.plan !== "Free"){
                    if (store.pages.length < 3) {
                    store.pages.forEach(async(page, index) => {
                      const indexString = store.pages.length > 1 ? '-' + (index + 1) : '';
                      store.saveAsImage({
                        pageId: page.id,
                        pixelRatio: quality,
                        mimeType: 'image/' + type,
                        fileName: getName() + indexString + '.' + type,
                      });
                    });
                  } 
                  } else {
                    if (store.pages.length < 3) {
                      // console.log("THis One is calling if condition")
                      store.pages.forEach(async (page, index) => {
                        // Load the image to be downloaded
                        const url = await store.toDataURL({
                          pageId: page.id,
                          pixelRatio: quality,
                          mimeType: 'image/' + type,
                        });
                    
                        // Create a new image element for the original image
                        const img = new Image();
                        img.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                        img.src = url;
                    
                        // Wait for the image to load
                        await new Promise((resolve) => {
                          img.onload = resolve;
                        });
                    
                        // Create a canvas to draw the image and watermark
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;
                    
                        // Draw the original image
                        ctx.drawImage(img, 0, 0);
                    
                        // Load the watermark image
                        const watermarkImage = new Image();
                        watermarkImage.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                        watermarkImage.src = 'https://cdrnplacid.s3.ap-south-1.amazonaws.com/generated-images/a03a633a-fd95-4aa7-8ba2-46daf7593511/9ba38c69-cac4-40d8-b66e-2cdd0d9828b4.jpeg'; // Sample watermark image
                    
                        // Wait for the watermark image to load
                        await new Promise((resolve) => {
                          watermarkImage.onload = resolve;
                        });
                    
                        // Set the opacity for the watermark (only)
                        ctx.globalAlpha = 0.2; // Set opacity to 30% (70% transparent)
                    
                        // Draw the watermark on the canvas, covering the entire canvas
                        ctx.drawImage(watermarkImage, 0, 0, img.width, img.height); // Cover the entire image
                    
                        // Reset the global alpha to 1.0 for any further drawings
                        ctx.globalAlpha = 0.3;
                    
                        // Convert the canvas to a data URL
                        const finalImageUrl = canvas.toDataURL('image/' + type);
                    
                        // do not add index if we have just one page
                        const indexString = store.pages.length > 1 ? '-' + (index + 1) : '';
                        downloadFile(finalImageUrl, getName() + indexString + '.' + type);
                      });
                    }
                  }
                } 
                  // if (store.pages.length < 3) {
                  //   // console.log("THis One is calling if condition")
                  //   store.pages.forEach(async (page, index) => {
                  //     // Load the image to be downloaded
                  //     const url = await store.toDataURL({
                  //       pageId: page.id,
                  //       pixelRatio: quality,
                  //       mimeType: 'image/' + type,
                  //     });
                  
                  //     // Create a new image element for the original image
                  //     const img = new Image();
                  //     img.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                  //     img.src = url;
                  
                  //     // Wait for the image to load
                  //     await new Promise((resolve) => {
                  //       img.onload = resolve;
                  //     });
                  
                  //     // Create a canvas to draw the image and watermark
                  //     const canvas = document.createElement('canvas');
                  //     const ctx = canvas.getContext('2d');
                  //     canvas.width = img.width;
                  //     canvas.height = img.height;
                  
                  //     // Draw the original image
                  //     ctx.drawImage(img, 0, 0);
                  
                  //     // Load the watermark image
                  //     const watermarkImage = new Image();
                  //     watermarkImage.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                  //     watermarkImage.src = 'https://cdrnplacid.s3.ap-south-1.amazonaws.com/generated-images/a03a633a-fd95-4aa7-8ba2-46daf7593511/9ba38c69-cac4-40d8-b66e-2cdd0d9828b4.jpeg'; // Sample watermark image
                  
                  //     // Wait for the watermark image to load
                  //     await new Promise((resolve) => {
                  //       watermarkImage.onload = resolve;
                  //     });
                  
                  //     // Set the opacity for the watermark (only)
                  //     ctx.globalAlpha = 0.2; // Set opacity to 30% (70% transparent)
                  
                  //     // Draw the watermark on the canvas, covering the entire canvas
                  //     ctx.drawImage(watermarkImage, 0, 0, img.width, img.height); // Cover the entire image
                  
                  //     // Reset the global alpha to 1.0 for any further drawings
                  //     ctx.globalAlpha = 0.3;
                  
                  //     // Convert the canvas to a data URL
                  //     const finalImageUrl = canvas.toDataURL('image/' + type);
                  
                  //     // do not add index if we have just one page
                  //     const indexString = store.pages.length > 1 ? '-' + (index + 1) : '';
                  //     downloadFile(finalImageUrl, getName() + indexString + '.' + type);
                  //   });
                  // }
                  
                  else {
                    // const zip = new JSZip();
                    // for (const page of store.pages) {
                    //   const index = store.pages.indexOf(page);
                    //   const indexString =
                    //     store.pages.length > 1 ? '-' + (index + 1) : '';

                    //   const url = await store.toDataURL({
                    //     pageId: page.id,
                    //     pixelRatio: quality,
                    //     mimeType: 'image/' + type,
                    //   });
                    //   const fileName = getName() + indexString + '.' + type;
                    //   const base64Data = url.replace(
                    //     /^data:image\/(png|jpeg);base64,/,
                    //     ''
                    //   );
                    //   zip.file(fileName, base64Data, { base64: true });
                    // }

                    // const content = await zip.generateAsync({ type: 'base64' });
                    // const result = 'data:application/zip;base64,' + content;
                    // console.log(content);
                    // downloadFile(result, getName() + '.zip');
                    const zip = new JSZip();
                        for (const page of store.pages) {
                          const index = store.pages.indexOf(page);
                          const indexString = store.pages.length > 1 ? '-' + (index + 1) : '';

                          // Load the image to be downloaded
                          const url = await store.toDataURL({
                            pageId: page.id,
                            pixelRatio: quality,
                            mimeType: 'image/' + type,
                          });

                          // Create a new image element for the original image
                          const img = new Image();
                          img.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                          img.src = url;

                          // Wait for the image to load
                          await new Promise((resolve) => {
                            img.onload = resolve;
                          });

                          // Create a canvas to draw the image and watermark
                          const canvas = document.createElement('canvas');
                          const ctx = canvas.getContext('2d');
                          canvas.width = img.width;
                          canvas.height = img.height;

                          // Draw the original image
                          ctx.drawImage(img, 0, 0);

                          // Load the watermark image
                          const watermarkImage = new Image();
                          watermarkImage.crossOrigin = 'anonymous'; // Set crossOrigin attribute
                          watermarkImage.src = 'https://cdrnplacid.s3.ap-south-1.amazonaws.com/images/b16a6611-6400-40b1-9590-231ef9ccaaca-blob'; // Sample watermark image

                          // Wait for the watermark image to load
                          await new Promise((resolve) => {
                            watermarkImage.onload = resolve;
                          });

                          // Set the opacity for the watermark (only)
                          ctx.globalAlpha = 0.3; // Set opacity to 30% (70% transparent)

                          // Draw the watermark on the canvas, covering the entire canvas
                          ctx.drawImage(watermarkImage, 0, 0, img.width, img.height); // Cover the entire image

                          // Reset the global alpha to 1.0 for any further drawings
                          ctx.globalAlpha = 1.0;

                          // Convert the canvas to a data URL
                          const finalImageUrl = canvas.toDataURL('image/' + type);

                          // Prepare the file name and base64 data for the zip
                          const fileName = getName() + indexString + '.' + type;
                          const base64Data = finalImageUrl.replace(/^data:image\/(png|jpeg);base64,/, '');

                          // Add the final image to the zip
                          zip.file(fileName, base64Data, { base64: true });
                        }

                        // Generate the zip file and download it
                        const content = await zip.generateAsync({ type: 'base64' });
                        const result = 'data:application/zip;base64,' + content;
                        console.log(content);
                        downloadFile(result, getName() + '.zip');
                  }
                }
              } catch (e) {
                // throw into global error handler for reporting
                setTimeout(() => {
                  throw e;
                });
                alert('Something went wrong. Please try again.');
              }
              setSaving(false);
            }}
          >
            Download {type.toUpperCase()}
          </Button>

          {/* <MenuItem
            icon="media"
            text={t('toolbar.saveAsImage')}
            onClick={async () => {
              store.pages.forEach((page, index) => {
                // do not add index if we have just one page
                const indexString =
                  store.pages.length > 1 ? '-' + (index + 1) : '';
                store.saveAsImage({
                  pageId: page.id,
                  fileName: getName() + indexString + '.png',
                });
              });
            }}
          />
          <MenuItem
            icon="document"
            text={t('toolbar.saveAsPDF')}
            onClick={async () => {
              setSaving(true);
              await store.saveAsPDF({
                fileName: getName() + '.pdf',
              });
              setSaving(false);
            }}
          /> */}
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button
        icon="import"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium  rounded text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
        text={t('toolbar.download')}
        intent="primary"
        // loading={saving}
        onClick={() => {
          setQuality(1);
        }}
      />
    </Popover>
  );
});
