import CryptoJS from 'crypto-js';

const secretKey = 'sdsdsds'; // Replace with your secret key

export const encryptId = (id) => {
    const ciphertext = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();

    // Encode to Base64
    let encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(ciphertext));

    // Replace special characters with alphanumeric equivalents
    encoded = encoded.replace(/\+/g, '0').replace(/\//g, '1').replace(/=/g, '2');

    return encoded;
};
export const decryptId = (encoded) => {
    // console.log('1')
    const ciphertext = decodeURIComponent(encoded);
    const decryptedData = CryptoJS.enc.Base64.parse(ciphertext).toString(CryptoJS.enc.Utf8);
    // console.log('2',decryptedData)
    // Decrypt the ID
    const bytes = CryptoJS.AES.decrypt(decryptedData, secretKey);
    const originalId = bytes.toString(CryptoJS.enc.Utf8);

    return originalId;
};