import React, { useState, useContext } from "react";
import { login } from "../APIservice/apiService";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { Spinner } from "flowbite-react";

const Login = () => {
  const navigate = useNavigate();
  const { UserName, updateUser } = useContext(UserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, seterrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const gosignup = () => navigate("/signup");
  const gosignup1 = () => navigate("/forgot");

  const logindetails = async (event) => {
    event.preventDefault();
    const validateEmail = (email) => {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(email.toLowerCase());
    };
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.password) errors.password = "Password is required";

    if (Object.keys(errors).length > 0) {
      seterrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const response = await login(formData.email, formData.password);
      setIsLoading(false);
      if (response.name) {
        navigate("/dashboard");
        updateUser({
          name: response.name,
          email: response.email,
          credits: response.credits,
          plan: response.plan,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="flex min-h-screen bg-white ">
      {/* Container */}
      <div className="flex flex-row w-full">
        {/* Sidebar */}
        <div className="hidden lg:flex flex-col justify-between bg-black text-white lg:p-8 xl:p-12 lg:max-w-sm xl:max-w-lg">
          <div className="flex items-center justify-start space-x-3">
            <div className="rounded-full border-5 border-black">
              <img
                src={require("../assets/3.png")}
                alt="logo"
                className="w-75 h-10"
              />
            </div>
           
          </div>
          <div className="space-y-5">
            <h1 className="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold">
              Enter your account and discover new experiences
            </h1>
            <p className="text-lg">You do not have an account?</p>
            <button
              onClick={() => navigate("/signup")}
              className="inline-block flex-none px-4 py-3 border-2 rounded-lg font-medium border-black bg-white text-black hover:bg-[#4c6ee2] hover:text-white duration-200"
            >
              Create account here
            </button>
          </div>
          <p className="font-medium"> @2024 ImageEazy</p>
        </div>

        {/* Login */}
        <div className="flex flex-1 flex-col items-center justify-center px-10 relative">
          <div className="flex lg:hidden justify-between items-center w-full py-4">
            <div className="flex items-center justify-start space-x-3">
           
            </div>
            {/* <div className="flex items-center space-x-2">
              <span>Not a member? </span>
              <a
                onClick={() => navigate("/signup")}
                className="underline font-medium text-[#070eff] cursor-pointer"
              >
                Sign up now
              </a>
            </div> */}
            
          </div>

         

          {/* Login box */}
          <div className="flex flex-1 flex-col justify-center space-y-5 max-w-md">
            <div className="flex flex-col space-y-2 text-center">
            
            <div className="flex items-center justify-center">
            <img
                src={require("../assets/1.png")}
                alt="logo"
                className="w-30 h-15 mb-4 lg:hidden max-w-[75%]"
              />
            </div>
              <h2 className="text-3xl md:text-4xl font-bold">Welcome Back</h2>
              <p className="text-md md:text-xl">
                Log in to continue creating amazing designs with ImageEazy
              </p>
            </div>
            <form onSubmit={logindetails} className="flex flex-col max-w-md space-y-5">
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    seterrors({ ...errors, email: "" });
                  }}
                  className="flex w-full px-3  border-2 py-2 md:px-4 md:py-3  border-black rounded-lg font-medium placeholder:font-normal"
                />
                {errors.email && (
                  <p className="text-red-500 mb-0 mt-1">{errors.email}</p>
                )}
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    seterrors({ ...errors, password: "" });
                  }}
                  className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                />
                {errors.password && (
                  <p className="text-red-500 mb-0 mt-1">{errors.password}</p>
                )}
              </div>
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => navigate("/forgot")}
                  className="underline font-medium text-[#4c6ee2] hover:text-black duration-200 cursor-pointer"
                >
                  Forgot Password?
                </button>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] duration-700"
              >
                {isLoading ? (
                  <>
                    <Spinner aria-label="Loading..." />
                    <span className="pl-3">Loading...</span>
                  </>
                ) : (
                  "Sign In"
                )}
              </button>
              
              <div className="flex items-center justify-center space-x-3">
                <div className="w-full h-[1px] bg-black"></div>
                <span className="text-sm text-black whitespace-nowrap">Or continue with</span>
                <div className="w-full h-[1px] bg-black"></div>
              </div>
              <button
                type="button"
                className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black hover:bg-gray-50 duration-200 relative"
              >
                <span className="absolute left-4">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#EA4335" d="M5.26620003,9.76452941 C6.19878754,6.93863203 8.85444915,4.90909091 12,4.90909091 C13.6909091,4.90909091 15.2181818,5.50909091 16.4181818,6.49090909 L19.9090909,3 C17.7818182,1.14545455 15.0545455,0 12,0 C7.27006974,0 3.1977497,2.69829785 1.23999023,6.65002441 L5.26620003,9.76452941 Z"/>
                    <path fill="#34A853" d="M16.0407269,18.0125889 C14.9509167,18.7163016 13.5660892,19.0909091 12,19.0909091 C8.86648613,19.0909091 6.21911939,17.076871 5.27698177,14.2678769 L1.23746264,17.3349879 C3.19279051,21.2936293 7.26500293,24 12,24 C14.9328362,24 17.7353462,22.9573905 19.834192,20.9995801 L16.0407269,18.0125889 Z"/>
                    <path fill="#4A90E2" d="M19.834192,20.9995801 C22.0291676,18.9520994 23.4545455,15.903663 23.4545455,12 C23.4545455,11.2909091 23.3454545,10.5272727 23.1818182,9.81818182 L12,9.81818182 L12,14.4545455 L18.4363636,14.4545455 C18.1187732,16.013626 17.2662994,17.2212117 16.0407269,18.0125889 L19.834192,20.9995801 Z"/>
                    <path fill="#FBBC05" d="M5.27698177,14.2678769 C5.03832634,13.556323 4.90909091,12.7937589 4.90909091,12 C4.90909091,11.2182781 5.03443647,10.4668121 5.26620003,9.76452941 L1.23999023,6.65002441 C0.43658717,8.26043162 0,10.0753848 0,12 C0,13.9195484 0.444780743,15.7301709 1.23746264,17.3349879 L5.27698177,14.2678769 Z"/>
                  </svg>
                </span>
                <span>Sign in with Google</span>
              </button>
              <div className="flex items-center justify-center space-x-2 lg:hidden">
              <span>Not a member? </span>
              <a
                onClick={() => navigate("/signup")}
                className=" underline font-medium text-[#4c6ee2] hover:text-black duration-200 cursor-pointer"
              >
                Sign up now
              </a>
            </div>
            </form>
          </div>

          {/* Footer */}
       
        </div>
      </div>
    </div>
  );
};

export default Login;
